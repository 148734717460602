import React from 'react';
import { graphql, PageProps } from 'gatsby';

import populatePageElements from '../helpers/populate-page-elements';

import Layout from '../components/Layout';

const HomePage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  const { elements, navElements, footerElements, termsElements } = populatePageElements(data);

  return (
    <Layout navElements={navElements} footerElements={footerElements} termsElements={termsElements}>
      {elements}
    </Layout>
  );
};

export default HomePage;

export { Head } from '../components/Head';

export const query = graphql`
  query HomePage {
    contentfulPage(pageName: { eq: "Galaxy Home Page" }) {
      components {
        __typename
        ... on ContentfulNavigation {
          id
          navLinks {
            title
            url
          }
          twitterLink
          instagramLink
          linkedInLink
        }
        ... on ContentfulFooter {
          id
          image {
            gatsbyImageData
          }
          footerLinks {
            title
            url
          }
          twitterLink
          instagramLink
          footerText {
            raw
          }
          linkedInLink
        }
        ... on ContentfulFaqSection {
          id
          faqList {
            question
            answer {
              raw
            }
          }
        }
        ... on ContentfulAboutTheDrop {
          id
          sectionHeader
          stat1
          stat1Description
          stat2
          stat2Description
          stat3
          stat3Description
          stat4
          stat4Description
        }
        ... on ContentfulAboutTheArtists {
          id
          artist1Title
          artist1Caption {
            raw
          }
          artist1Image {
            gatsbyImageData
          }
          artist2Title
          artist2Caption {
            raw
          }
          artist2Image {
            gatsbyImageData
          }
          artist3Title
          artist3Caption {
            raw
          }
          artist3Image {
            gatsbyImageData
          }
          buyNowUrl
          sectionHeader
          sectionDetails {
            raw
          }
        }
        ... on ContentfulWhyGalaxy {
          id
          sectionHeader
          sectionDetails {
            raw
          }
        }
        ... on ContentfulHeader {
          id
          projectName
          projectInfo {
            raw
          }
          secondaryDescription {
            raw
          }
          secondaryDescriptionRegistered {
            raw
          }
          secondaryDescriptionRegistrationHash {
            raw
          }
          secondaryDescriptionRegistrationNoHash {
            raw
          }
          image {
            gatsbyImageData
          }
        }
        ... on ContentfulTerms {
          id
          title
          terms {
            childMarkdownRemark {
              html
            }
          }
          termsImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
